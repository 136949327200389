<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('SiteCreate')"
    @edit="(id) => onEdit('SiteEdit', id)"
    class="sites-list"
  >
    <template #list-top-panet>
      <sm-button
        :disabled="isFillingSites"
        @click="onClickFillSites()"
        class="sites-list__fill-btn"
      >Заполнить адреса сайтов</sm-button>
    </template></sm-editable-list>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { mapActions } from 'vuex';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'Sites',

  components: {
    SmEditableList,
    SmButton,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Sites',
      tableCaption: 'Сайты клиентов',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Сайты клиентов',
        },
      ],
      tableHeaders: [
        { text: 'Название', alias: 'name', order: 'name' },
        {
          text: 'Название клиента',
          alias: 'customerName',
          order: 'customerName',
        },
        {
          text: 'Код клиента',
          alias: 'customerId',
          order: 'customerId',
        },
        {
          text: 'Демо-код',
          alias: 'demoCode',
        },
        { text: 'Адрес сайта', alias: 'url', order: 'url' },
        { text: 'Дата создания', alias: 'created', order: 'created' },
        {
          text: 'Не контролировать выгрузку в ГИС ЖКХ',
          alias: 'dontControlGisExport',
          order: 'dontControlGisExport',
        },
        { alias: 'actions' },
      ],
      isFillingSites: false,
    };
  },

  methods: {
    ...mapActions({
      fillSites: 'sites/fillSites',
    }),

    onClickFillSites() {
      if (this.isFillingSites) return;

      this.fillSites();
      this.$notify({
        header: 'Заполнение адресов сайтов было успешно запущено',
        type: 'success',
        timer: 5000,
      });
      this.isFillingSites = true;
    },
  },
};
</script>

<style lang="scss">
.sites-list__fill-btn {
  width: 240px;
  margin-bottom: 20px;
  margin-top: 30px;
}
</style>
